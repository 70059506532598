#mh-tab-flex .window {
  width: 100%;
  height: 460px;
  border-radius: 10px;
  background: transparent;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#mh-tab-flex ul,
#mh-tab-flex li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: Barlow, sans-serif;
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 715px) {
    font-size: 14px;
  }

  @media (max-width: 515px) {
    font-size: 11px;
  }
}

#mh-tab-flex ul {
  display: flex;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

#mh-tab-flex li {
  margin-right: 10px;
  border-radius: 8px;
  width: 100%;
  padding: 10px 0px;
  position: relative;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  user-select: none;
}

#mh-tab-flex li:last-child {
  margin-right: 0px;
}

#mh-tab-flex li span {
  flex-shrink: 1;
  flex-grow: 1;
  border-radius: 6px;
  white-space: nowrap;
  display: block;
  min-width: 0;
  text-align: center;
  height: 35px;
  padding-top: 8px;
  flex-direction: row;

  @media (max-width: 715px) {
    height: 32px;
  }

  @media (max-width: 515px) {
    height: 22px;
  }
}

#mh-tab-flex li button {
  width: 20px;
  height: 20px;
  border: 0;
  background: #fff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  flex-shrink: 0;
}