@import url("https://fonts.googleapis.com/css?family=Barlow:400,500,700&subset=latin,latin-ext&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap");
@font-face {
  font-family: "Road Rage";
  src: url("./assets/fonts/RoadRage.otf") format("opentype");
}

html {
  color-scheme: dark;
  color: #f7fcfd;
  font-family: Barlow, sans-serif;
  height: 100%;
  text-size-adjust: 100%;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  margin: 0px;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  height: 100%;
  position: relative;
  margin: 0;
  width: 100%;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 17px;
  height: 100%;
  z-index: 999;
  background: #192832;
  border-left: 1px solid #20323f;
}

body::-webkit-scrollbar-thumb {
  position: relative;
  display: block;
  height: 20px;
  width: 12px;
  border-radius: 4px;
  margin-left: 2px;
  /* background-color: luminance("#253a49", -0.1); */
  background-color: #253a49;
  cursor: pointer;
}

* {
  -webkit-tap-highlight-color: transparent;
}

@keyframes shake {
  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
  10% {
    transform: translate(-10px, -5px) rotate(-2deg);
  }
  20% {
    transform: translate(15px, 5px) rotate(2deg);
  }
  30% {
    transform: translate(-20px, 0px) rotate(-3deg);
  }
  40% {
    transform: translate(20px, 10px) rotate(3deg);
  }
  50% {
    transform: translate(-15px, -10px) rotate(-2deg);
  }
  60% {
    transform: translate(10px, 5px) rotate(2deg);
  }
  70% {
    transform: translate(-10px, -5px) rotate(-2deg);
  }
  80% {
    transform: translate(5px, 0px) rotate(1deg);
  }
  90% {
    transform: translate(-5px, 5px) rotate(-1deg);
  }
}

.shake {
  animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both; /* Shake for 0.8 seconds */
}
