.jsType {
  min-width: 100px;
  display: inline-block;
  transition: transform 0s, opacity 0s;
  text-align: left;
  letter-spacing: 0;
  transform-origin: 50% 50%;
}

.jsType.animate-out {
  opacity: 0;
  transition: transform 1s, opacity 1.4s;
}